import React from 'react'
import Layout from "../templates/Layout"
import Accordian from "../components/Accordian"
import { Hero, Container, Row} from "@edinburghairport/runway"
import { Link } from "gatsby"


class FAQs extends React.Component {


  

  render() {

    const faqs1 = {
      heading: "1. General overview of Airspace Change Programme",
      items: [
          {
              title: "What are you doing?",
              content: "<p>We are going through an Airspace Change Programme in order to make proposed changes to the arrival and departure routes from Edinburgh Airport.</p>"
          },
          {
              title: "Why are you doing an Airspace Change Programme?",
              content: "<p>We believe that airspace modernisation is the best way to achieve the increased capacity that the airport needs. It will allow us to grow in the future, it will modernise our flight paths and we think it will benefit the country. Changes to legislation also mean that we need to upgrade to newer technology which allows aircraft to follow more accurate flight paths, you can read more about RNAV technology further down this page.</p>"
          },
          {
              title: "How can I make a complaint about noise I am currently experiencing?",
              content: "<p>Complaints regarding aircraft noise can be made by:</p><ul><li>Phone – our free noise complaints line 0800 731 3397 is available 24/7</li><li>Email – noise@edinburghairport.com </li><li>Letter – Noise complaint, Communications team, Edinburgh Airport, EH12 9DN</li></ul><p>All complaints are registered and investigated. To allow us to fully investigate your complaint, please include your name, address including postcode, contact details and specific details of your complaint, with dates and times of the disturbance. Names and addresses will never be made public or used for any other purpose.</p><p>We aim to respond to complaints within five working days. However, when a more detailed investigation is required we will send an acknowledgment email advising when you can expect a full response.</p>"
          }
      ]
  }
  
  const faqs2 = {
      heading: "2. Technical questions",
      items: [
          {
              title: "What is the CAA?",
              content: "<p>The Civil Aviation Authority (CAA) is the government organisation responsible for the regulation and safety of Air Transport in the UK. Amongst other things they are responsible for the planning and regulation of all UK airspace, including the navigation and communications infrastructure to support safe and efficient operations. The CAA is staffed by civilian experts from the CAA and military experts from the Ministry of Defence (MoD) with experience of commercial, business/private, recreational and military aviation. The needs of all users are accommodated, as far as possible, taking into account safety, environmental, economic and national security considerations. The CAA are in turn answerable to the Department for Transport, and the Secretary of State for Transport.</p>"
          },
          {
              title: "You have fewer flights than in 2007 so why do you need to do it now?",
              content: "<p>Looking at old CAA data does not show the whole picture. We do have fewer flights, but in 2007 we had a higher mix of traffic, such as more flying club planes. In 2019 we’re a growing international airport and that growth is during our peak times, particularly first thing in the morning and in the early evening.</p><p>At these peaks, our existing infrastructure struggles to cope. By modernising our airspace, we can ensure we can deal with the peaks now and as the airport grows and develops.</p>"
          },
          {
              title: "You're already growing, why do you need to do this?",
              content: "<p>Precisely because we are growing - our infrastructure needs extra capacity at peak time and we want to ensure that we can grow further.</p>"
          },
      
          {
              title: "The CAA is engaged with developing a Future Airspace Strategy or FAS. This is a major   collection of projects looking at everything from the routes aircraft fly to flight performance information.  How does the Edinburgh Airport project relate to the FAS?",
              content: "<p>The FAS requires improvement of navigation standards and recommends that routes are upgraded to RNAV1. The Edinburgh Airport Project is proposing to upgrade the navigation performance by introducing RNAV1 routes.</p>"
          }
      ]
  }
  
  const faqs7 = {
      heading: "2. FAQs from public meeting in Inverkeithing",
  items: [{
      title: "Over the last 4 weeks a constant stream of planes have passed directly over my house in Inverkeithing - is this a change that has already been made? Was this consulted on beforehand?",
      content: "<p>There have been no changes to the flightpaths. The existing flight paths have been in operation since the runway was opened in the 1970s. No new flight paths are being flown at the moment, no trials of routes have taken place, and the earliest new flight paths will be flown will be Spring 2019.</p>"},
      {
    title: "Booklet says no night flights, yet over the past 2 weeks flights have taken off between 01:00 to 03:00?",
      content: "<p>There have been no changes to the flightpaths. Currently Dalgety Bay is overflown by aircraft on 06-GOSAM departure route, this route operates 24/7. Our proposal would reduce night time flying over Dalgety Bay. The proposed route E7a would only operate 06:00 to 23:00 and this is what is referred to in the booklet.</p>"},
      {title: "Why was WHO (World Health Organization) guidance on noise not used in either consultation?</strong></p>",
      content: "<p>Our Airspace Change application must comply with the CAP725 policy set out by our regulator, the Civil Aviation Authority (CAA). WHO and the European Union is currently in the process of developing the WHO Environmental Noise Guidelines for the European Region as a regional update to the WHO Community Noise Guidelines. Previous guidance was issued in 1999. Our Environmental Impact Assessment (EIA) models noise as Lnight as suggested by WHO guidance update (2002) and in addition to other noise indicators.</p>"},
      {title: "Why concentrate flights on immediate coastal area over Dalgety Bay? If aircraft took a slight turn over Cramond Island after take off, a loop over the Forth would miss all residential areas except Braefoot Bay which is industrial anyway?",
      content: "<p>We understand that residents want to see a flight path that avoids their area as much as possible however we operate under strict parameters and cannot put forward any proposal that will have a negative impact on those communities already flown over. That includes no increase in track mileage, no increase in fuel burn or fuel consumption. We have looked at a range of options further east over the Forth to try and minimise noise disruption and they do not adhere to these parameters which is why we have not put that option forward. It must meet guidelines to be considered by the CAA.</p>"},
      {title: "Why is this consultation on proposed flight path changes not looking at options to improve the flightpath and take it further away from densely populated areas such as Dalgety Bay? The information in the documentation is trying to persuade us it will be no worse than currently, why not aim for better? What effect would using routes G & H rather than E7a, have on EAL, financially and operationally?",
      content: "<p>Currently Dalgety Bay is overflown by aircraft on 06-GOSAM departure route - this route operates 24/7. Our proposal would reduce night time flying over Dalgety Bay. The proposed route would only operate 06:00 to 23:00. We understand that residents want to see a flight path that avoids their area as much as, we operate under strict parameters and cannot put forward any proposal that will have a negative impact on those communities already flown over. That includes no increase in track mileage, no increase in fuel burn or fuel consumption. We have looked at a range of options further east over the Forth to try and minimise noise disruption and they do not adhere to these parameters which is why we have not put that option forward. It must meet guidelines to be considered by the CAA.</p>"},
      {title: "In the second consultation, E1a and E1b, which are largely identical to E7a proposed, failed to comply with safety/ International Civil Aviation Organization (ICAO) design criteria, why does E7a not now fail to comply?",
      content: "<p>E7a complies with the requirements of both ICAO and safety design criteria as detailed within the legislation we apply for Airspace Change under CAP725.</p>"},
      {title: "I don’t know why the changes have to be implemented now . Why can’t there be a delay? Is it absolutely necessary that we suffer these changes right away? Can’t they be postponed?",
      content: "<p>Applying for Airspace Change takes a substantial period of time to work through the various stages of the process and our current Airspace Change application began this process back in 2016. To ensure we have the runway capacity that we require and the move to RNAV technology we cannot postpone this process. Further information on RNAV technology is available within our second consultation documentation.</p>"},
      {title: "Is there a choice of direction of take off when there is 'no wind'?",
      content: "<p>A basic aspect of aviation safety is that aircraft need to land and take off into the wind. They can take off in the same direction as the wind, but this is only allowed if the wind speed is up to 5 knots, which is little more than a breeze. Decisions on the direction of runway usage are the sole remit of Air Traffic Control who are responsible for maintaining the safe and efficient management of air traffic within our airspace.</p>"},
      {title: "Pushed through before CAP 1616 are enforced?",
      content: "<p>As the airspace change process at Edinburgh Airport commenced under CAP725 we are continuing under those regulations. This approach has been agreed with the CAA. </p>"},
      {title: "Why has there been no sound tests in affected areas like Dalgety Bay, Aberdour, South Queensferry, Inverkeithing?",
      content: "<p>These proposed routes are not in operation so we have used projected noise levels. These models can be seen within the CAA ERCD and the EIA. If the routes are ratified then prior to them coming into operation noise measurements will be taken within communities to establish current noise levels, followed by regular re-measurements to gain an understanding of noise levels and evaluate the noise modelling. </p>"},
      {title: "Concerned about future proofing - if this flight path is approved we have no guarantees that it won’t be used 24/7  because of increased demand. The 6am start is already hugely noisy and 2 min intervals at capacity would be intolerable.",
    content: "<p>Any change to times of use for this departure route, if approved, would require further consultation via the CAP1616 process. The airport cannot make any changes to those times without going through this consultation process and subsequently approval from the CAA. </p>"},
  {title: "On page 5 of the consultation booklet it says that by 2019 there will be 42 flights per day - how has this figure been arrived at? There were around 130 flights from 11:30 today to end of day, your estimate seems low.",
  content: "<p>The total number of departures in 2016 was 56915, with 11587 of those on runway 06 (departing towards the Forth). That equates to 21% of flights and the equivalent of 76 days of full-time usage which we have based this on. It must be noted that wind direction dictates the direction of arrival and departure, in turn dictating the number of hours runway 06 is used, so the number of flight has been averaged out to reflect this.</p>"},
  {title: "In North Queensferry we have the noise and pollution of the trains, traffic over the Forth Road Bridge and Queensferry Crossing. What is the impact on North Queensferry’s community health with the proposed changes?<br/>The present consultation is to consider the impact from the change in the E7 flight path and makes assumptions that current disturbance to our quality of life re noise and pollution are acceptable. This is not so, Environmental considerations have changed considerably since the current flight paths were adopted. So we should review plans taking them from first principles. What happens if the noise is above Scottish government Guidelines on the new flight path?",
  content: "<p>The Environmental Impact Assessment was written and analysed by Ricardo and within the document’s noise section  modelling and analysis was carried out by Anderson Acoustics. The CAA ERCD also carries our noise  analysis and modelling. The EIA fully analyses various potential environmental impacts of the route amendment, including health, noise, tranquillity impacts and the impacts any changes may have on schools and hospitals.</p><p>The Scottish Government does not have guidelines on aircraft noise.</p>"},
  {title: "Why have the residents in the eastern expansion of Dunfermline not been included in the supplementary consultation, we are currently plagued by low flying planes while the wind is easterly and we are very likely to be troubled by the proposals",
  content: "<p>The new flight path E7a is designed to pull aircraft away from Dunfermline which will also reduce noise.  </p>"},
  {title: "At the moment flights over Aberdour are horrific, even though there is no east wind - why?",
  content: "<p>The new flight path E7a is designed to pull aircraft away from Aberdour which will also reduce noise. </p>"},
  {title: "If these proposals go ahead are you going to provide compensation to affected householders for the loss of value to their property and for loss of quality of life?",
  content: "<p>Edinburgh Airport must provide factual information during this consultation process. It is not appropriate for the airport to comment on certain issues until the end of the consultation period to avoid  swaying public opinion and responses.</p>"},
      ]}

    const content = {
      background: {
        file: {
          url: "https://edinburghairport.s3-eu-west-1.amazonaws.com/files/acp2019/faq_bg.png"
        }
      },
      heading: "Frequently asked questions",
    };
    
    return (
      <Layout>
        <Hero content={content} />
        <div className="section section__faqs" id="faqs">
          <Container>
            <Row>
              <div className="col-12">
                <p className="breadcrumb"><Link href="/">Home</Link> > FAQs</p>
              </div>
            </Row>
            <Row>

              <div className="col-md-12">
                <div className="section__faqs">
                <Accordian heading={faqs1.heading} items={faqs1.items}/>
                <Accordian heading={faqs2.heading} items={faqs2.items}/>
                {/* <h3 id="2">2.FAQs from public meeting in Inverkeithing</h3> */}
                {/* <Accordian heading={faqs7.heading} items={faqs7.items} richText={false}/> */}
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default FAQs