import React, { Component } from "react";
import { Heading, Button } from '@edinburghairport/runway'

class Accordian extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      showItem: false,
    }

    this.toggleAccordian = this.toggleAccordian.bind(this);
  }

  toggleAccordian(e, index) {
    e.preventDefault();

    this.setState({
      showItem: index === this.state.showItem ? false : index
    })
  }

  render() {
    const { modifiers = '', heading, items} = this.props;
    return (
      <section className={`accordian ${modifiers}`} >
        { heading && <Heading level="2">{heading}</Heading> }
        { items && items.map((item, index) => (
          <div className="accordian__item" key={index}>
            <Button modifiers={`accordian__button ${this.state.showItem === index ? 'show': ''}`} onClick={(e) => {this.toggleAccordian(e, index)}}>{item.title}</Button>
            { this.state.showItem === index  &&
              <div className={`accordian__content`} dangerouslySetInnerHTML={{ __html: item.content }}>

              </div>
            }
          </div>
        ))}
      </section>
    )
  }
};

export default Accordian;